<template>
  <div class="container mx-auto py-10 select-none text-white">
    <AppTitle title="Nuestros clientes" />
    <div
      class="
        w-full
        flex flex-wrap
        justify-center
        pt-16
        pb-5
        items-center
        gap-4
        md:gap-x-6 md:gap-y-6
      "
    >
      <a
        v-for="(cliente, index) in clientes"
        :key="index"
        :class="[
          'w-28 h-12 md:w-48 md:h-16 flex justify-center items-center transition duration-500 hover:scale-110 transform z-50',
        ]"
        :style="{ padding: cliente.padding }"
        :href="cliente.url"
        target="_blank"
      >
        <img
          :src="require('../assets/clientes/' + cliente.img)"
          class="
            w-full
            h-auto
            max-h-full
            filter
            drop-shadow-lg
            object-contain
            cliente
            transition
            duration-300
          "
          :style="{ filter: cliente.filter }"
          :alt="`Logo ${cliente.nombre}`"
        />
      </a>
    </div>
  </div>
</template>

<script>
import AppTitle from "./AppTitle.vue";
export default {
  components: { AppTitle },
  setup() {
    const clientes = [
      {
        nombre: "We Golf",
        img: "wegolf.png",
        url: "https://www.we.golf",
        filter: "brightness(3) saturate(0) opacity(0.8)",
      },
      {
        nombre: "Feli",
        img: "feli.png",
        url: "https://www.feli.com.uy",
        filter: "brightness(0.8) saturate(0) opacity(1)",
      },
      {
        nombre: "Rumbo",
        img: "rumbo.png",
        url: "https://www.rumbosrl.com.ar",
        padding: ".6rem",
        filter: "brightness(3) saturate(0) opacity(0.8)",
      },
      {
        nombre: "Enseñanza con sentido",
        img: "ensenianzaconsentido.png",
        url: "https://ensenanzaconsentido.com.ar",
        padding: ".3rem 0",
        filter: "brightness(1) saturate(0) opacity(.9)",
      },
      {
        nombre: "El Trebol",
        img: "eltrebol.png",
        url: "https://eltrebolpremiumstore.com.ar",
        padding: ".2rem 0",
        filter: "brightness(.9) saturate(0) opacity(.9)",
      },
      {
        nombre: "Todo Metabo",
        img: "metabo.png",
        url: "https://todometabo.com.ar",
        padding: ".8rem",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Instituto de cálculo",
        img: "ic.png",
        url: "https://www.ic.fcen.uba.ar",
        filter: "brightness(.9) saturate(0) opacity(.9)",
      },
      {
        nombre: "Gruposi",
        img: "gruposi.png",
        url: "http://gruposi.com.ar",
        filter: "brightness(1.4) saturate(0) opacity(.9)",
      },
      {
        nombre: "Learnba",
        img: "learnba.png",
        url: "http://learnba.pomakana.com",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Todohusqvarna",
        img: "todohusqvarna.png",
        url: "https://www.todohusqvarna.com.ar/jardin/home",
        padding: "0 .7rem",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Varteco",
        img: "varteco.png",
        url: "http://varteco.com.ar",
        padding: "0.7rem",
        filter: "brightness(1.9) saturate(0) opacity(1)",
      },
      {
        nombre: "illa",
        img: "illa.png",
        url: "https://www.illadelsbous.com",
        filter: "brightness(0.8) saturate(0) opacity(1)",
      },
      {
        nombre: "Molamaq",
        img: "molamaq.png",
        url: "https://www.molamaq.com/jardin/home",
        padding: "0 .5rem",
        filter: "brightness(3.5) saturate(0) opacity(.9)",
      },
      {
        nombre: "o2bip",
        img: "o2bip.png",
        url: "http://o2bip.pomakana.com",
        filter: "brightness(1.4) saturate(0) opacity(.9)",
      },
      {
        nombre: "Reagens",
        img: "reagens.png",
        url: "http://reagensvarteco.com.ar",
        filter: "brightness(2) saturate(0) opacity(.9)",
      },
      {
        nombre: "Jardines de Garzón",
        img: "jardines.jpg",
        url: "https://www.instagram.com/jardinesdegarzonsrl/",
        filter: "brightness(1.2) saturate(0) opacity(.9)",
      },
      {
        nombre: "oh! Wear",
        img: "oh-wear.png",
        url: "https://oh-wear.com/",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Brain.code",
        img: "Brain.code.png",
        url: "https://programas.brainandcode.tech/programas",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Wecorp",
        img: "wecorp.png",
        url: "https://wecorp.biz",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Edison capacitores",
        img: "edison-capacitores.png",
        url: "https://edisoncapacitores.com",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Bioleft",
        img: "bioleft.png",
        url: "https://www.bioleft.org/es/",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Shesarg",
        img: "shesarg.png",
        url: "http://www.shesarg.com",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Grupo-haras",
        img: "grupo-haras.png",
        url: "https://grupoharas.pe//",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "Beatriz Zuazo",
        img: "zuazo.png",
        url: "https://www.entrevetas.com",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "DBPM",
        img: "dbpm.png",
        url: "https://dbpmconsulting.com",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
      {
        nombre: "vismara",
        img: "vismara.png",
        url: "https://vismarahnos.com.ar",
        filter: "brightness(.8) saturate(0) opacity(.9)",
      },
    ];
    return { clientes };
  },
};
</script>

<style scoped>
.cliente:hover {
  filter: none !important;
}
</style>
